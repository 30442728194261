<template>
  <section id="copy-survey-chart" class="copy-survey-chart">
    <div class="copy-survey-chart__form">
      <vs-input
        :label="lang.surveyCharts.create.form.name.label[languageSelected]"
        :placeholder="
          lang.surveyCharts.create.form.name.placeholder[languageSelected]
        "
        class="w-full"
        v-model="chartCopy.name"
        name="name"
      />

      <BotNoVersionFilter
        class="mt-6"
        :multiple-select="false"
        @change="onBotChange"
        :allow-empty="true"
      />

      <!-- TODO: refactor survey -->
      <div class="mt-2">
        <label for="chart-survey">
          {{ lang.surveyCharts.create.form.survey.label[languageSelected] }}
        </label>
        <multiselect
          id="chart-survey"
          v-model="selectedSurvey"
          :options="surveys"
          track-by="_id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="
            lang.surveyCharts.create.form.survey.label[languageSelected]
          "
          name="survey"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('survey') }"
          @select="onSurveySelected"
        />
        <span class="error-msg" v-if="errors.first('survey')">
          {{ errors.first('survey') }}
        </span>
      </div>
    </div>
    <div class="mt-6 copy-survey-chart__actions">
      <vs-button
        type="flat"
        class="float-left mr-4"
        color="danger"
        @click="cancel"
      >
        {{ lang.surveyCharts.create.form.actions.cancel[languageSelected] }}
      </vs-button>
      <vs-button
        color="primary"
        :disabled="
          !chartCopy.name || !chartCopy.botService || !chartCopy.surveyService
        "
        @click.stop="copy"
      >
        {{ lang.surveyCharts.copy.title[languageSelected] }}
      </vs-button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import services from '../../survey-maker/services'

export default {
  name: 'CopySurveyChart',
  props: {
    chart: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chartCopy: {
        name: '',
        botService: null,
        surveyService: null
      },
      surveys: [],
      selectedSurvey: null
    }
  },
  components: {
    BotNoVersionFilter: () =>
      import('../../metrics/filters/components/BotNoVersionFilter.vue'),
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    cancel() {
      this.$emit('onCancel')
      // this.reset()
    },
    copy() {
      this.$emit('onCopy', {
        ...this.chart,
        ...this.chartCopy
      })
    },
    onSurveySelected(survey) {
      this.chartCopy.surveyService = survey.service
    },
    onBotChange(bot) {
      this.chartCopy.botService = bot.value[0]
    },
    async getSurveys() {
      try {
        const result = await services.getSurveys()
        this.surveys = result.data.map(element => ({
          _id: element._id,
          service: element.service._id,
          name: element.service.name,
          _createdAt: element._createdAt
        }))
      } catch (error) {
        //TODO: create message
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.text[
            this.languageSelected
          ],
          color: 'danger'
        })
      }
    },
    async initSurveys() {
      this.$vs.loading()
      try {
        await this.getSurveys()
      } catch (error) {
        //TODO: create message
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.text[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  mounted() {
    this.initSurveys()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
